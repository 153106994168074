import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import moment from "moment"

import { Table, DatePicker } from "antd"

const Wallets = observer(({ walletsList, isLoading, getWallets }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Wallet",
      dataIndex: "wNumber",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
    {
      title: "Owner",
      dataIndex: "userId",
    },
    {
      title: "Create",
      render: (record) => {
        return moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
      },
    },
  ]

  useEffect(() => {
    getWallets()
  }, [getWallets])

  return (
    <div className="col">
      <div className="row">
        <div className="col">Filters</div>
      </div>
      <div className="row">
        <div className="col">
          <Table
            columns={columns}
            dataSource={walletsList}
            loading={isLoading}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  )
})

const withData = inject((stores) => ({
  walletsList: stores.WalletsStore.walletsList,
  isLoading: stores.WalletsStore.isLoading,
  getWallets: stores.WalletsStore.getWallets,
}))

export default withData(Wallets)
