import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"

import LoginPage from "./pages/LoginPage"
import MainPage from "./pages/MainPage"
import WalletsPage from "./pages/WalletsPage"
import TransactionsPage from "./pages/TransactionsPage"
import TransactionsLogPage from "./pages/TransactionsLogPage"

import "./App.scss"

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={LoginPage} exact />
        <Route path="/dashboard" component={MainPage} />
        <Route path="/wallets" component={WalletsPage} />
        <Route path="/transactions" component={TransactionsPage} />
        <Route path="/transactions-log" component={TransactionsLogPage} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
