import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class TransactionsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable transactionsList = []

  @action
  getTransactions = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}v1/transactions`, true)
      this.transactionsList = response.result
      this.isLoading = false
      this.isError = false
      this.errorMsg = null
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }

  @action
  refound = async (amount, orderReference) => {
    try {
      const response = await Http.post(
        `${authApi}v1/wayforpay/refound`,
        { amount, orderReference },
        true
      )
      this.getTransactions()
    } catch (e) {
      this.errorMsg = e
    }
  }
}

export default new TransactionsStore()
