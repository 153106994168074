import React from "react"
import { MdMenu } from "react-icons/md"
import { inject, observer } from "mobx-react"
import { Redirect } from "react-router-dom"

import "./Header.scss"

const Header = ({ toggleDrawer, title, logout, isLogin }) => {
  if (!isLogin) {
    return <Redirect to="/" />
  }

  return (
    <div className="col header">
      <div className="left-side">
        <button className="menu-toggle" onClick={toggleDrawer}>
          <MdMenu />
        </button>
        <div className="header-title">{title}</div>
      </div>
      <div className="right-side">
        <button className="btn btn-outline-light" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  )
}

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
  logout: stores.AuthStore.logout,
  relogin: stores.AuthStore.relogin,
}))

export default withData(Header)
