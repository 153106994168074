import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class WalletsStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable walletsList = []

  @action
  getWallets = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}v1/wallets`, true)
      this.walletsList = response.result
      this.isLoading = false
      this.isError = false
      this.errorMsg = null
    } catch (e) {
      this.isLoading = false
      this.isError = true
      this.errorMsg = e
    }
  }
}

export default new WalletsStore()
