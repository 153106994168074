import { observable, action } from "mobx"
import Http from "../../helpers/http"
import { authApi } from "../../configs/api"

class HistoryStore {
  @observable isLoading = false
  @observable isError = false
  @observable errorMsg = null
  @observable historyRecords = []

  @action
  getHistoryRecords = async () => {
    try {
      this.isLoading = true
      const response = await Http.get(`${authApi}`)
      this.isLoading = false
      if (response.status === 200) {
        this.historyRecords = response.data.records
      }
    } catch (e) {
      this.isLoading = false
      this.errorMsg = e
      this.isError = true
    }
  }
}

export default new HistoryStore()
