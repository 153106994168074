export default class Http {
  static HEADERS = {
    "Content-Type": "application/json",
  }

  static async get(url, useToken = false) {
    try {
      return await request(url, "GET", null, useToken)
    } catch (e) {
      return e
    }
  }

  static async post(url, data = {}, useToken = false) {
    try {
      return await request(url, "POST", data, useToken)
    } catch (e) {
      return e
    }
  }

  static async put(url, data = {}, useToken = false) {
    try {
      return await request(url, "PUT", data, useToken)
    } catch (e) {
      return e
    }
  }

  static async delete(url, useToken = false) {
    try {
      return await request(url, "DELETE", null, useToken)
    } catch (e) {
      return e
    }
  }
}

async function request(url, method = "GET", data, useToken = false) {
  const config = {
    method,
    headers: Http.HEADERS,
  }

  if (useToken && localStorage.getItem("token")) {
    config.headers.Authorization = localStorage.getItem("token")
  }

  if (method === "POST" || method === "PUT") {
    config.body = JSON.stringify(data)
  }

  const response = await fetch(url, config)
  return await response.json()
}
