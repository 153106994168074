import AuthStore from "./pages/LoginPage/mobx"
import HistoryStore from "./components/PaymentsHistory/mobx"
import WalletsStore from "./components/Wallets/mobx"
import TransactionsStore from "./components/Transactions/mobx"
import TransactionsLogStore from "./components/TransactionsLog/mobx"

export default {
  AuthStore,
  HistoryStore,
  WalletsStore,
  TransactionsStore,
  TransactionsLogStore,
}
