import React, { useEffect } from "react"
import { inject, observer } from "mobx-react"
import moment from "moment"

import { GrRevert } from "react-icons/gr"

import { Table } from "antd"

const Transactions = observer(
  ({ transactionsList, isLoading, getTransactions, refound }) => {
    const columns = [
      {
        title: "Operation id",
        dataIndex: "orderReference",
      },
      {
        title: "Wallet id",
        dataIndex: "wallet",
      },
      {
        title: "Type of transaction",
        render: (record) => {
          if (record.transactionType === 1) {
            return <>Wallet replenishment</>
          }

          if (record.transactionType === 2) {
            return <>Output on card</>
          }

          return <></>
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Status",
        dataIndex: "transactionStatus",
      },
      {
        title: "Date of operation",
        render: (record) => {
          return moment(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
        },
      },
      {
        title: "",
        render: (record) => {
          if (
            record.transactionStatus === "Approved" &&
            record.transactionType === 1
          ) {
            return (
              <div
                className="refound-btn"
                onClick={() => refound(record.amount, record.orderReference)}
              >
                <GrRevert style={{ color: "#fff" }} />
              </div>
            )
          }
          return <></>
        },
      },
    ]

    useEffect(() => {
      getTransactions()
    }, [getTransactions])

    return (
      <div className="col">
        <div className="row">
          <div className="col">Filters</div>
        </div>
        <div className="row">
          <div className="col">
            <Table
              columns={columns}
              dataSource={transactionsList}
              loading={isLoading}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
    )
  }
)

const withData = inject((stores) => ({
  transactionsList: stores.TransactionsStore.transactionsList,
  isLoading: stores.TransactionsStore.isLoading,
  getTransactions: stores.TransactionsStore.getTransactions,
  refound: stores.TransactionsStore.refound,
}))

export default withData(Transactions)
