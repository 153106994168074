import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { Helmet } from "react-helmet"

import Header from "../../components/Header"
import DrawerMenu from "../../components/DrawerMenu"
import Transactions from "../../components/Transactions"

const TransactionsPage = observer(() => {
  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer)
  }

  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <div className="container-fluid">
        <div className="row">
          <Header toggleDrawer={toggleDrawer} title="Transactions" />
        </div>
        <div className="row">
          <Transactions />
        </div>
      </div>
      <DrawerMenu status={showDrawer} onClose={toggleDrawer} />
    </>
  )
})

const withData = inject((stores) => ({
  isLogin: stores.AuthStore.isLogin,
}))

export default withData(TransactionsPage)
