import React from "react"
import { Drawer } from "antd"
import { Link } from "react-router-dom"

import "antd/dist/antd.css"
import "./DrawerMenu.scss"

const DrawerMenu = (props) => {
  const { onClose, status } = props
  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={status}
      className="drawer-menu"
    >
      <div class="drawer-menu-block">
        <Link to="/wallets">Wallets</Link>
        <Link to="/transactions">Transaction history</Link>
        <Link to="/transactions-log">Transaction logs</Link>
      </div>
    </Drawer>
  )
}

export default DrawerMenu
