import React, { useState } from "react"
import { inject, observer } from "mobx-react"
import { Redirect } from "react-router-dom"

import "./LoginPage.scss"

const LoginPage = observer((props) => {
  const { isError, isLoading, isLogin } = props

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [inputErrs, setInputErrs] = useState({
    email: "",
    password: "",
  })

  const tryAuth = () => {
    if (email.length < 6) {
      const errors = { ...inputErrs, email: "Email too short" }
      setInputErrs(errors)
      return
    }

    if (password.length < 8) {
      const errors = {
        ...inputErrs,
        password: "Password must be at least 8 characters",
      }
      setInputErrs(errors)
      return
    }

    props.tryAuth(email, password)
  }

  const chgEmail = (e) => {
    if (inputErrs.email.length > 0) {
      setInputErrs({ ...inputErrs, email: "" })
    }
    setEmail(e.target.value)
  }

  const chgPassword = (e) => {
    if (inputErrs.password.length > 0) {
      setInputErrs({ ...inputErrs, password: "" })
    }
    setPassword(e.target.value)
  }

  if (isLogin) {
    return <Redirect to="/dashboard" />
  }

  return (
    <div className="row">
      <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div className="card card-signin my-5">
          <div className="card-body">
            {isError ? (
              <div className="alert alert-danger">
                Invalid username and / or password
              </div>
            ) : null}
            <form className="form-signin">
              {inputErrs.email.length > 0 ? (
                <div className="alert alert-danger">{inputErrs.email}</div>
              ) : null}
              <div className="form-label-group rows-v-margin">
                <input
                  name="login"
                  value={email}
                  onChange={chgEmail}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  autoFocus
                />
              </div>
              {inputErrs.password.length > 0 ? (
                <div className="alert alert-danger">{inputErrs.password}</div>
              ) : null}
              <div className="form-label-group rows-v-margin">
                <input
                  name="pwd"
                  value={password}
                  onChange={chgPassword}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                />
              </div>
              <button
                className="btn btn-lg btn-primary btn-block"
                type="button"
                onClick={tryAuth}
                disabled={isLoading}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
})

const withData = inject((stores) => ({
  isError: stores.AuthStore.isError,
  isLoading: stores.AuthStore.isLoading,
  isLogin: stores.AuthStore.isLogin,
  tryAuth: stores.AuthStore.tryAuth,
}))

export default withData(LoginPage)
